<script setup lang="ts">
import { DropProps } from '@/interfaces';
import { vClickOutside } from '@/directives/clickOutside';

const props = defineProps<{
  isDisable?: boolean;
  container: DropProps;
}>()

const show = ref(false)

const containerStyle = computed(() => ({
  top: props.container.top,
  left: props.container.left,
  right: props.container.right,
  bottom: props.container.bottom,
  'max-height': props.container.maxHeight,
}))

const toggle = (status?: boolean) => {
  if (props.isDisable) return (show.value = false);
  show.value = status || !show.value;
}

const clickOutside = () => {
  show.value = false;
}
</script>

<template>
  <div class="drop" v-click-outside="clickOutside">
    <div class="drop-header cursor-pointer" @click="toggle()">
      <slot name="header" :show="show" :toggle="toggle"></slot>
    </div>
    <transition name="fade">
      <div v-if="show" class="drop-container" :style="containerStyle">
        <slot name="container" :toggle="toggle" :show="show"></slot>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
.drop {
  @apply relative;
  &-container {
    @apply absolute bg-white z-20;
  }
}
</style>
